<template lang="pug">
  router-view
</template>

<script>
export default {
  name: 'PageView',
  setup() {}
}
</script>
